import { EventFormat } from './enums/event-format';
import { EventStatus } from './enums/event-status.enum';
import { NetGross } from './enums/net-gross.enum';
import { SelectItem } from './modules/fs-controls-shared.model';
import {
  FSLayerSelector,
  FSLayerSelectorItem,
} from './modules/fs-controls/fs-layer-selector/fs-layer-selector.model';
import { FSRadioButtons } from './modules/fs-controls/fs-radio-buttons/fs-radio-buttons.model';
import {
  FsSelectorFieldDataSource,
  FsSelectorFieldValue,
} from './modules/fs-controls/fs-selector-field/fs-selector-field.model';

export const PasswordMaxLength = 36;
export const AutoRefreshDelay = 45000;

export const ScreenBreakpoint = 868;
export const InvalidIdMessage = 'invalid id';

export const HandicapRange = {
  min: 0,
  max: 54,
  default: 0,
};

export const GrossRange = {
  min: 1,
  max: 99,
  default: 1,
};

export const NetRange = {
  min: -2,
  max: 99,
  default: -2,
};

//Navbar items
export const NavbarItemsData: Array<any> = [
  {
    id: 1,
    name: 'home',
    view: 'Home',
    route: 'home',
  },
  {
    id: 2,
    name: 'eventManager',
    view: 'Event Manager',
    route: 'event-manager',
  },
];

export const LeagueRoundsData = new FsSelectorFieldDataSource<number>({
  values: [
    new FsSelectorFieldValue({
      name: '1',
      value: 1,
    }),
    new FsSelectorFieldValue({
      name: '2',
      value: 2,
    }),
    new FsSelectorFieldValue({
      name: '3',
      value: 3,
    }),
    new FsSelectorFieldValue({
      name: '4',
      value: 4,
    }),
    new FsSelectorFieldValue({
      name: '5',
      value: 5,
    }),
    new FsSelectorFieldValue({
      name: '6',
      value: 6,
    }),
    new FsSelectorFieldValue({
      name: '7',
      value: 7,
    }),
    new FsSelectorFieldValue({
      name: '8',
      value: 8,
    }),
    new FsSelectorFieldValue({
      name: '9',
      value: 9,
    }),
    new FsSelectorFieldValue({
      name: '10',
      value: 10,
    }),
    new FsSelectorFieldValue({
      name: '11',
      value: 11,
    }),
    new FsSelectorFieldValue({
      name: '12',
      value: 12,
    }),
  ],
});

export const LeagueHolesData = new FsSelectorFieldDataSource<number>({
  values: [
    new FsSelectorFieldValue({
      name: '18',
      value: 18,
    }),
    new FsSelectorFieldValue({
      name: '9',
      value: 9,
    }),
  ],
});

export const LeaguePlayersData = new FsSelectorFieldDataSource<number>({
  values: [
    new FsSelectorFieldValue({
      name: 'Single',
      value: 1,
    }),
    new FsSelectorFieldValue({
      name: 'Team',
      value: -1,
      childs: {
        direction: 'column',
        values: [
          new FsSelectorFieldValue({
            name: '2/Team',
            value: 2,
          }),
          new FsSelectorFieldValue({
            name: '3/Team',
            value: 3,
          }),
          new FsSelectorFieldValue({
            name: '4/Team',
            value: 4,
          }),
        ],
      },
    }),
  ],
});

export const LeagueFormatDataForSingle = new FsSelectorFieldDataSource<number>({
  values: [
    new FsSelectorFieldValue({
      name: 'Stroke Play',
      value: EventFormat.Stroke,
    }),
  ],
});

export const LeagueFormatDataForTeam = new FsSelectorFieldDataSource<number>({
  values: [
    new FsSelectorFieldValue({
      name: 'Scramble',
      value: EventFormat.Scramble,
    }),
    new FsSelectorFieldValue({
      name: 'Best Ball',
      value: EventFormat.BestBall,
    }),
  ],
});

export const LeagueFormatData = new FsSelectorFieldDataSource<number>({
  values: [
    new FsSelectorFieldValue({
      name: 'Stroke Play',
      value: 1,
      childs: {
        direction: 'column',
        values: [...LeagueFormatDataForSingle.values],
      },
    }),
  ],
});

export const LeagueLeaderboardData = new FsSelectorFieldDataSource<number>({
  values: [
    new FsSelectorFieldValue({
      name: 'Total Strokes',
      value: 1,
    }),
    new FsSelectorFieldValue({
      name: 'W/L',
      value: 2,
    }),
  ],
});

export const TournamentLeaderboardData = new FsSelectorFieldDataSource<number>({
  values: [
    new FsSelectorFieldValue({
      name: 'Stroke',
      value: 1,
    }),
  ],
});

export const ModeData = new FsSelectorFieldDataSource<number>({
  values: [
    new FsSelectorFieldValue({
      name: 'Field Strokes',
      value: 1,
    }),
  ],
});

export const LeagueScoringData = new FsSelectorFieldDataSource<number>({
  values: [
    new FsSelectorFieldValue({
      name: 'Gross',
      value: 1,
    }),
    new FsSelectorFieldValue({
      name: 'Net',
      value: 2,
    }),
    new FsSelectorFieldValue({
      name: 'Both',
      value: 3,
    }),
  ],
});

export const LeagueScoringScrambleData = new FsSelectorFieldDataSource<number>({
  values: [
    new FsSelectorFieldValue({
      name: 'Gross',
      value: 1,
    }),
  ],
});

export const TeeBoxData: FSLayerSelector = new FSLayerSelector({
  id: 1,
  layerSize: 56,
  values: [
    new FSLayerSelectorItem({
      background: '#FFC108',
      id: 1,
      label: 'CH',
      color: '#000000',
      //value: '7,354y',
    }),
    new FSLayerSelectorItem({
      background: '#000000',
      id: 2,
      label: 'BK',
      color: '#ffffff',
      //value: '6,865y',
    }),
    new FSLayerSelectorItem({
      background: '#3369FF',
      id: 3,
      label: 'BL',
      color: '#ffffff',
      //value: '6,642y',
    }),
    new FSLayerSelectorItem({
      background: '#FFFFFF',
      id: 4,
      label: 'WH',
      color: '#000000',
      //value: '6,487y',
    }),
    new FSLayerSelectorItem({
      background: '#E51616',
      id: 5,
      label: 'RD',
      color: '#ffffff',
      //value: '5,047y',
    }),
    new FSLayerSelectorItem({
      background: '#7ED321',
      id: 6,
      label: 'GR',
      color: '#000000',
      //value: '4,798y',
    }),
  ],
});

export const getTeePositionName = (id: number): string => {
  switch (id) {
    case 1:
      return 'Championship';
    case 2:
      return 'Black';
    case 3:
      return 'Blue';
    case 4:
      return 'White';
    case 5:
      return 'Red';
    case 6:
      return 'Green';
    default:
      return '';
  }
};

export const FrontNineData = new FsSelectorFieldDataSource<number>({
  direction: 'column',
  values: [
    new FsSelectorFieldValue({
      name: 'F9',
      value: 1,
    }),
    new FsSelectorFieldValue({
      name: 'B9',
      value: 2,
    }),
  ],
});

export const SimSettingsData = new FsSelectorFieldDataSource<number>({
  values: [
    new FsSelectorFieldValue({
      name: 'PREFERENCES',
      value: 1,
    }),
    new FsSelectorFieldValue({
      name: 'CONDITIONS',
      value: 2,
    }),
  ],
});

export const SoundFXData = new FsSelectorFieldDataSource<number>({
  values: [
    new FsSelectorFieldValue({
      name: 'ON',
      value: 2,
    }),
    new FsSelectorFieldValue({
      name: 'OFF',
      value: 1,
    }),
  ],
});

export const PuttingArrowData = new FsSelectorFieldDataSource<number>({
  values: [
    new FsSelectorFieldValue({
      name: 'FADE',
      value: 3,
    }),
    new FsSelectorFieldValue({
      name: 'OFF',
      value: 2,
    }),
    new FsSelectorFieldValue({
      name: 'ON',
      value: 1,
    }),
  ],
});

export const PuttingGridData = new FsSelectorFieldDataSource<number>({
  values: [
    new FsSelectorFieldValue({
      name: 'FADE',
      value: 3,
    }),
    new FsSelectorFieldValue({
      name: 'OFF',
      value: 2,
    }),
    new FsSelectorFieldValue({
      name: 'ON',
      value: 1,
    }),
  ],
});

export const PuttingUnitData = new FsSelectorFieldDataSource<number>({
  values: [
    new FsSelectorFieldValue({
      name: 'FT',
      value: 1,
    }),
    new FsSelectorFieldValue({
      name: 'CM',
      value: 2,
    }),
  ],
});

export const FullShotUnitsData = new FsSelectorFieldDataSource<number>({
  values: [
    new FsSelectorFieldValue({
      name: 'YD',
      value: 1,
    }),
    new FsSelectorFieldValue({
      name: 'M',
      value: 2,
    }),
  ],
});

export const PlayerViewData = new FsSelectorFieldDataSource<number>({
  values: [
    new FsSelectorFieldValue({
      name: 'BALL',
      value: 2,
    }),
    new FsSelectorFieldValue({
      name: 'CLASSIC',
      value: 1,
    }),
  ],
});

export const TracerViewData = new FsSelectorFieldDataSource<number>({
  values: [
    new FsSelectorFieldValue({
      name: 'SOLID',
      value: 2,
    }),
    new FsSelectorFieldValue({
      name: 'COMET',
      value: 1,
    }),
    new FsSelectorFieldValue({
      name: 'NONE',
      value: 3,
    }),
  ],
});

export const CourseElevationData = new FsSelectorFieldDataSource<number>({
  values: [
    new FsSelectorFieldValue({
      name: 'COURSE',
      value: 1,
    }),
    new FsSelectorFieldValue({
      name: 'SEA LVL',
      value: 0,
    }),
    new FsSelectorFieldValue({
      name: 'CUSTOM',
      value: -1,
    }),
  ],
});

export const IsRoundPlayableData = new FsSelectorFieldDataSource<number>({
  values: [
    new FsSelectorFieldValue({
      name: 'Active',
      value: 1,
      classes: 'gr-med-clr',
    }),
    new FsSelectorFieldValue({
      name: 'Inactive',
      value: 2,
      classes: 'danger-clr',
    }),
  ],
});

export const MulliganData = new FsSelectorFieldDataSource<number>({
  values: [
    new FsSelectorFieldValue({
      name: 'UNLTD',
      value: 1000,
    }),
    new FsSelectorFieldValue({
      name: 'OFF',
      value: 0,
    }),
    new FsSelectorFieldValue({
      name: '3',
      value: 3,
    }),
    new FsSelectorFieldValue({
      name: '5',
      value: 5,
    }),
    new FsSelectorFieldValue({
      name: '7',
      value: 7,
    }),
  ],
});

export const GimmiesData = new FsSelectorFieldDataSource<number>({
  values: [
    new FsSelectorFieldValue({
      name: '8 FT',
      value: 3,
    }),
    new FsSelectorFieldValue({
      name: '10 FT',
      value: 4,
    }),
    new FsSelectorFieldValue({
      name: '6 FT',
      value: 2,
    }),
    new FsSelectorFieldValue({
      name: 'AUTO',
      value: 5,
    }),
  ],
});

export const PinPostionData = new FsSelectorFieldDataSource<number>({
  values: [
    new FsSelectorFieldValue({
      name: 'EASY',
      value: 1,
    }),
    new FsSelectorFieldValue({
      name: 'MED',
      value: 2,
    }),
    new FsSelectorFieldValue({
      name: 'HARD',
      value: 3,
    }),
  ],
});

export const GreenSpeedData = new FsSelectorFieldDataSource<number>({
  values: [
    new FsSelectorFieldValue({
      name: 'MED',
      value: 2,
    }),
    new FsSelectorFieldValue({
      name: 'SLOW',
      value: 1,
    }),
    new FsSelectorFieldValue({
      name: 'FAST',
      value: 3,
    }),
  ],
});

export const GreenFirmnessData = new FsSelectorFieldDataSource<number>({
  values: [
    new FsSelectorFieldValue({
      name: 'NORM',
      value: 2,
    }),
    new FsSelectorFieldValue({
      name: 'SOFT',
      value: 1,
    }),
    new FsSelectorFieldValue({
      name: 'FIRM',
      value: 3,
    }),
  ],
});

export const FairwayData = new FsSelectorFieldDataSource<number>({
  values: [
    new FsSelectorFieldValue({
      name: 'NORM',
      value: 2,
    }),
    new FsSelectorFieldValue({
      name: 'SOFT',
      value: 1,
    }),
    new FsSelectorFieldValue({
      name: 'FIRM',
      value: 3,
    }),
  ],
});

export const TerrainPenaltyData = new FsSelectorFieldDataSource<number>({
  values: [
    new FsSelectorFieldValue({
      name: 'OFF',
      value: 1,
    }),
    new FsSelectorFieldValue({
      name: 'ON',
      value: 2,
    }),
  ],
});

export const WindData = new FsSelectorFieldDataSource<number>({
  values: [
    new FsSelectorFieldValue({
      name: 'OFF',
      value: 3,
    }),
    new FsSelectorFieldValue({
      name: 'BREEZY',
      value: 1,
    }),
    new FsSelectorFieldValue({
      name: 'WINDY',
      value: 2,
    }),
  ],
});

//Player per team radio buttons
export const PlayerPerTeamData: FSRadioButtons = new FSRadioButtons({
  id: 1,
  title: 'Player Per Team',
  values: [
    new SelectItem({
      id: 1,
      isActive: true,
      name: '2',
      value: '2',
    }),
    new SelectItem({
      id: 2,
      isActive: true,
      name: '3',
      value: '3',
    }),
    new SelectItem({
      id: 3,
      isActive: true,
      name: '4',
      value: '4',
    }),
  ],
});

//Net gross switcher
export const NetGrossData = new FsSelectorFieldDataSource({
  values: [
    new FsSelectorFieldValue({
      name: 'NET',
      value: NetGross.Net,
    }),
    new FsSelectorFieldValue({
      name: 'GROSS',
      value: NetGross.Gross,
    }),
  ],
});

export const GrossData = new FsSelectorFieldDataSource({
  values: [
    new FsSelectorFieldValue({
      name: 'GROSS',
      value: NetGross.Gross,
    }),
  ],
});

export const NetData = new FsSelectorFieldDataSource({
  values: [
    new FsSelectorFieldValue({
      name: 'NET',
      value: NetGross.Net,
    }),
  ],
});

// export const NetGrossData2: FSButtonsGroup = new FSButtonsGroup({
//   id: 1,
//   buttonsSize: 70,
//   hideNav: true,
//   values: [
//     new SelectItem({
//       id: 1,
//       name: 'NET',
//       value: NetGross.Net,
//     }),
//     new SelectItem({
//       id: 2,
//       name: 'GROSS',
//       value: NetGross.Gross,
//     }),
//   ],
// });

export const EventStatusData = new FsSelectorFieldDataSource<number>({
  values: [
    new FsSelectorFieldValue({
      name: 'Not Started',
      value: 1,
      childs: {
        direction: 'column',
        values: [
          new FsSelectorFieldValue({
            name: 'Not Started',
            value: EventStatus.NotStarted,
          }),
          new FsSelectorFieldValue({
            name: 'In Progress',
            value: EventStatus.InProgress,
          }),
          new FsSelectorFieldValue({
            name: 'Completed',
            value: EventStatus.Completed,
          }),
        ],
      },
    }),
  ],
});

export const EventRegistrationData = new FsSelectorFieldDataSource<number>({
  values: [
    new FsSelectorFieldValue({
      name: 'Open',
      value: 1,
      classes: 'gr-med-clr',
    }),
    new FsSelectorFieldValue({
      name: 'Close',
      value: 2,
      classes: 'danger-clr',
    }),
  ],
});

export const MyTeamsTableColumnWidths: number[] = [
  4.2, 20.6, 29.6, 17.2, 9.4, 10.2, 4.8,
];

//--------------Default object values-----------
//--------------Preferences values------------
interface IPreferencesDefaults {
  readonly Mulligans: number;
  readonly Gimmie: number;
  readonly TracerView: number;
  readonly PlayerView: number;
  readonly FullShotUnits: number;
  readonly PuttingUnitId: number;
  readonly SoundFx: boolean;
}

export const PreferencesDefaults: IPreferencesDefaults = {
  Mulligans: 1000,
  Gimmie: 3,
  TracerView: 2,
  PlayerView: 2,
  FullShotUnits: 1,
  PuttingUnitId: 1,
  SoundFx: true,
};

//-------------Conditions values----------------
interface IConditionsDefaults {
  readonly PinPosition: number;
  readonly GreenSpeed: number;
  readonly GreenFirmness: number;
  readonly PuttingGrid: number;
  readonly PuttingArrow: number;
  readonly FairwayFirmness: number;
  readonly TerrainPenalty: boolean;
  readonly WindSetting: number;
  readonly CourseElevation: number;
}

export const ConditionsDefaults: IConditionsDefaults = {
  PinPosition: 1,
  GreenSpeed: 2,
  GreenFirmness: 2,
  PuttingGrid: 3,
  PuttingArrow: 3,
  FairwayFirmness: 2,
  TerrainPenalty: true,
  WindSetting: 3,
  CourseElevation: 1,
};
